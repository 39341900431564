<template>
  <div>
    <edit-building
      data-test-id="edit-building"
      :project-id="projectId"
      :building-id="buildingId"
      :copy="copy"
      :title="$t('editBuildingTitle')"
      submit-text="validateAndSave"
      :handle-submit="validationSuccess"
    />
  </div>
</template>

<script>
import EditBuilding from '@/components/building/edit/EditBuilding'
import alerts from '@/_helpers/alerts'

export default {
  components: {
    EditBuilding
  },
  props: {
    projectId: String,
    buildingId: {
      type: String,
      default: null
    },
    copy: Boolean
  },
  methods: {
    validationSuccess() {
      alerts.showInfo('validateAndSaveBuildingSuccess')
      return this.projectId
    }
  }
}
</script>